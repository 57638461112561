export default [
  {
    header: 'Inventory',
  },

  {
    title: 'Search',
    route: 'inventory',
    icon: 'SearchIcon',
  },
  {
    title: 'Watching',
    route: 'inventory-favourites',
    icon: 'EyeIcon',
  },
  {
    title: 'Map',
    route: 'inventory-map',
    icon: 'MapIcon',
  },
]
