/* eslint-disable */
// prettier-ignore
export default {
  places: {
    key: "place_name",
    data: []
  },
  pages: {
    key: 'title',
    data: [
      { title: 'Account Settings', route: { name: 'pages-account-setting' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Chartjs', route: { name: 'charts-chartjs' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Dashboard Analytics', route: { name: 'dashboard-analytics' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Dashboard Sales', route: { name: 'dashboard-sales' }, icon: 'ShoppingCartIcon', isBookmarked: false },
      // { title: 'Echart', route: { name: 'charts-echart' }, icon: 'PieChartIcon', isBookmarked: false },
      // { title: 'Knowledge Base Category', route: { name: 'pages-knowledge-base-category' }, icon: 'InfoIcon', isBookmarked: false, },
      // { title: 'Knowledge Base Question', route: { name: 'pages-knowledge-base-question' }, icon: 'InfoIcon', isBookmarked: false, },
      // { title: 'Knowledge Base', route: { name: 'pages-knowledge-base' }, icon: 'InfoIcon', isBookmarked: false },
      { title: 'Map', route: { name: 'maps-leaflet' }, icon: 'MapPinIcon', isBookmarked: false },
      // { title: 'Time', route: { name: 'components-time' }, icon: 'ClockIcon', isBookmarked: false },
      // { title: 'Wishlist', route: { name: 'apps-e-commerce-wishlist' }, icon: 'HeartIcon', isBookmarked: false },
    ],
    //  ! Note: Set isBookMarked to false if you don't want the icon to appear at the top user/nav bar and completely remove the element all together if you don't want it to appear in search.
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Joe's CV",
        from: 'Stacy Watson',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.7 mb',
      },
      {
        file_name: 'Passport Image',
        from: 'Ben Sinitiere',
        icon: require('@/assets/images/icons/jpg.png'),
        size: ' 52 kb',
      },
      {
        file_name: 'Questions',
        from: 'Charleen Patti',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.5 gb',
      },
      {
        file_name: 'Parenting Guide',
        from: 'Doyle Blatteau',
        icon: require('@/assets/images/icons/doc.png'),
        size: '2.3 mb',
      },
      {
        file_name: 'Class Notes',
        from: 'Gwen Greenlow',
        icon: require('@/assets/images/icons/doc.png'),
        size: ' 30 kb',
      },
      {
        file_name: 'Class Attendance',
        from: 'Tom Alred',
        icon: require('@/assets/images/icons/xls.png'),
        size: '52 mb',
      },
      {
        file_name: 'Company Salary',
        from: 'Nellie Dezan',
        icon: require('@/assets/images/icons/xls.png'),
        size: '29 mb',
      },
      {
        file_name: 'Company Logo',
        from: 'Steve Sheldon',
        icon: require('@/assets/images/icons/jpg.png'),
        size: '1.3 mb',
      },
      {
        file_name: 'Crime Rates',
        from: 'Sherlock Holmes',
        icon: require('@/assets/images/icons/xls.png'),
        size: '37 kb',
      },
      {
        file_name: 'Ulysses',
        from: 'Theresia Wrenne',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '7.2 mb',
      },
      {
        file_name: 'War and Peace',
        from: 'Goldie Highnote',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '10.5 mb',
      },
      {
        file_name: 'Vedas',
        from: 'Ajay Patel',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '8.3 mb',
      },
      {
        file_name: 'The Trial',
        from: 'Sirena Linkert',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '1.5 mb',
      },
    ],
  },

}
/* eslint-enable */
