<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="algoliaIndexName"
  >
    <ais-configure :facets="['*']" />
    <div
      class="vertical-layout h-100"
      :class="[layoutClasses]"
      :data-col="isNavMenuHidden ? '1-column' : null"
    >
      <!-- Navbar -->
      <b-navbar
        :toggleable="false"
        :variant="navbarBackgroundColor"
        class="header-navbar navbar navbar-shadow align-items-center"
        :class="[navbarTypeClass]"
      >
        <slot
          name="navbar"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :navbarBackgroundColor="navbarBackgroundColor"
          :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
        >
          <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </slot>
      </b-navbar>
      <!--/ Navbar -->

      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        v-if="!isNavMenuHidden"
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
      <!-- /Vertical Nav Menu -->

      <!-- Vertical Nav Menu Overlay -->
      <div
        class="sidenav-overlay"
        :class="overlayClasses"
        @click="isVerticalMenuActive = false"
      />
      <!-- /Vertical Nav Menu Overlay -->

      <!-- Content -->

      <!-- CONTENT TYPE: Left -->
      <transition
        :name="routerTransition"
        mode="out-in"
      >
        <component
          :is="layoutContentRenderer"
          :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
        >
          <template
            v-for="(index, name) in $scopedSlots"
            #[name]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </component>
      </transition>
      <!--/ Content -->

      <!-- Footer -->
      <footer
        class="footer footer-light"
        :class="[footerTypeClass]"
      >
        <slot name="footer">
          <app-footer />
        </slot>
      </footer>
      <!-- /Footer -->

      <slot name="customizer" />
    </div>
  </ais-instant-search>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import algoliasearch from 'algoliasearch/lite'

import InventorySearchLayout from '../../../views/inventory/InventorySearchLayout.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    InventorySearchLayout,
  },
  mixins: [mixinVerticalLayout],
  setup() {
    // eslint-disable-next-line no-undef
    const algoliaIndexName = process.env.VUE_APP_PLATFORM_ALGOLIA_INDEX_PRODUCT_NAME
    // eslint-disable-next-line no-unused-vars
    const searchClient = algoliasearch(

      process.env.VUE_APP_PLATFORM_ALGOLIA_CREDENTIALS_APP_ID,

      process.env.VUE_APP_PLATFORM_ALGOLIA_CREDENTIALS_ADMIN_API_KEY,
    )
    // const index = searchClient.initIndex(algoliaIndexName)

    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,
      searchClient,
      algoliaIndexName,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
  data() {
    return {

    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      if (rendererType === 'sidebar-left-detached-inventory') return 'inventory-search-layout'
      return 'layout-content-renderer-default'
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
