<template>
  <ais-autocomplete>
    <li
      slot-scope="{ }"
      class="nav-item nav-search"
    >

      <!-- Icon -->
      <a
        href="javascript:void(0)"
        class="nav-link nav-link-search"
        @click="showSearchBar = true"
      >
        <feather-icon
          icon="SearchIcon"
          size="21"
        />
      </a>

      <!-- Input -->
      <div
        class="search-input"
        :class="{'open': showSearchBar}"
      >
        <div class="search-input-icon">
          <feather-icon icon="SearchIcon" />
        </div>
        <!-- <input type="text" placeholder="Explore ...." class="form-control-input"> -->
        <!-- @keyup.esc="escPressed" -->
        <!-- @keyup.enter="suggestionSelected" -->
        <b-form-input
          v-if="showSearchBar"
          v-model="searchQuery"
          placeholder="Explore Dahsboard"
          autofocus
          autocomplete="off"
          @keyup.up="increaseIndex(false)"
          @keyup.down="increaseIndex"
          @keyup.esc="showSearchBar = false; resetsearchQuery()"
          @keyup.enter="suggestionSelected"
          @blur.stop="showSearchBar = false; resetsearchQuery()"
        />
        <div
          class="search-input-close"
          @click="showSearchBar = false; resetsearchQuery()"
        >
          <feather-icon icon="XIcon" />
        </div>

        <!-- Suggestions List -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="search-list search-list-main scroll-area overflow-hidden"
          :class="{'show': searchQuery}"
          tagname="ul"
        >

          <li
            v-for="(suggestion_list, grp_name, grp_index) in filteredData"
            :key="grp_index"

            class="suggestions-groups-list"
          >

            <!-- Group Header -->
            <p class="suggestion-group-title">
              <span>
                {{ title(grp_name) }}
              </span>
            </p>

            <!-- Suggestion List of each group -->
            <ul>
              <li
                v-for="(suggestion, index) in suggestion_list"
                :key="index"
                class="suggestion-group-suggestion cursor-pointer"
                :class="{'suggestion-current-selected': currentSelected === `${grp_index}.${index}`}"
                @mouseenter="currentSelected = `${grp_index}.${index}`"
                @mousedown.prevent="suggestionSelected(grp_name, suggestion)"
              >
                <b-link
                  v-if="grp_name === 'places'"

                  class="p-0"
                >
                  <feather-icon
                    icon="GlobeIcon"
                    class="mr-75"
                  />
                  <span class="align-middle">{{ suggestion.place_name }}</span>
                </b-link>

                <template v-else-if="grp_name === 'pages'">

                  <div class="d-flex align-items-center">

                    <feather-icon
                      :icon="suggestion.icon"
                      class="mr-75"
                    />
                    <span class="align-middle">{{ suggestion.title }}</span>
                  </div>
                </template>

                <template v-else-if="grp_name === 'files'">
                  <div class="d-flex align-items-center">
                    <b-img
                      :src="suggestion.icon"
                      class="mr-1"
                      height="32"
                    />
                    <div>
                      <p>{{ suggestion.file_name }}</p>
                      <small>by {{ suggestion.from }}</small>
                    </div>
                    <small class="ml-auto">{{ suggestion.size }}</small>
                  </div>
                </template>

              </li>

              <li
                v-if="!suggestion_list.length && searchQuery"
                class="suggestion-group-suggestion no-results"
              >
                <p>No Results Found.</p>
              </li>
            </ul>
          </li>
        </vue-perfect-scrollbar>
      </div>
    </li>
  </ais-autocomplete>

</template>

<script>
import {
  BFormInput, BLink, BImg,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { title } from '@core/utils/filter'
import { connectGeoSearch } from 'instantsearch.js/es/connectors'
import { createWidgetMixin } from 'vue-instantsearch'
import { mapActions } from 'vuex'
import store from '@/store'
import router from '@/router'
import allSearch from '../../../../../views/inventory/search/searchBookmarksAndData'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    VuePerfectScrollbar,
  },
  mixins: [createWidgetMixin({ connector: connectGeoSearch })],
  setup() {
    const filteredData = ref({})

    const searchQuery = ref('')

    const resetsearchQuery = () => {
      searchQuery.value = ''
    }
    const showSearchBar = ref(false)
    // They use ref to make the showSearchBar reactive and accessible everywhere.

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    /* Here I am calling the data that needs to be searched andd pushing it to the search auto suggest function
     note that I am pushing all the data located in searchAndBookmarkData to the serach function and that data is being filteredd
     based on my search, and inject into filteredData. but also in the all search function I am doing a sperate request to mapbox fowar geocoding api
     and aslo injected into filteredData
*/
    allSearch({ data: searchAndBookmarkData, searchLimit: 4 }, filteredData, searchQuery)

    const suggestionSelected = function suggestionSelected(grpName, suggestion) {
      // If parameter is not provided => Use current selected
      if (!suggestion) {
        // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        /* eslint-disable no-use-before-define, no-param-reassign */
        if (currentSelected.value !== -1) {
          /* eslint-disable no-use-before-define, no-param-reassign */
          const [grpIndex, itemIndex] = currentSelected.value.split('.')
          grpName = Object.keys(filteredData.value)[grpIndex]
          suggestion = filteredData.value[grpName][itemIndex]
          /* eslint-enable */
        }
      }

      this.selectedItem = suggestion
      /* Here when the user makes a selection from the autocomplete if the suggestion is aplace I call algolia connect geo search
      (i am able to because I've intialised it's connect above. Now that i've done that i should)
      */
      this.saveSearchRefinement(suggestion)
      if (suggestion.bbox) {
        const boundingBox = {
          northEast: {
            lat: suggestion.bbox[1],
            lng: suggestion.bbox[0],
          },
          southWest: {
            lat: suggestion.bbox[3],
            lng: suggestion.bbox[2],
          },

        }
        this.state.refine(boundingBox)
      }

      if (grpName === 'pages') router.push(suggestion.route).catch(() => {})
      if (grpName === 'places') router.push({ name: 'inventory' }).then(() => suggestion).catch(() => {})
      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    const currentSelected = ref(-1)

    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    const increaseIndex = (val = true) => {
      /* eslint-disable no-lonely-if, no-plusplus */

      // If there's no matching items

      if (!Object.values(filteredData.value).some(grpItems => grpItems.length)) return
      const [grpIndex, itemIndex] = currentSelected.value.split('.') // ! Here

      const grpArr = Object.entries(filteredData.value)
      const activeGrpTotalItems = grpArr[grpIndex][1].length

      if (val) {
        // If active item is not of last item in grp
        if (activeGrpTotalItems - 1 > itemIndex) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) + 1}`

        // If active item grp is not last in grp list
        } else if (grpIndex < grpArr.length - 1) {
          for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${Number(i)}.0`
              break
            }
          }
        }
      } else {
        // If active item is not of first item in grp
        if (Number(itemIndex)) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) - 1}`

        // If active item grp  is not first in grp list
        } else if (Number(grpIndex)) {
          for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${i}.${grpArr[i][1].length - 1}`
              break
            }
          }
        }
      }
      /* eslint-enable no-lonely-if, no-plusplus */
    }

    return {
      showSearchBar,
      perfectScrollbarSettings,
      searchAndBookmarkData,
      title,
      suggestionSelected,
      currentSelected,
      increaseIndex,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
  data() {
    return {
      selectedItem: undefined,
    }
  },
  methods: {
    ...mapActions('search', [
      'saveSearchRefinement',
    ]),

  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
