export default [
  {
    header: 'Apps',
  },

  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageCircleIcon',
  },

]
