export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '3',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Sales',
        route: 'dashboard-sales',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
      {
        title: 'Behavioral',
        route: 'dashboard-behavioral',
      },
    ],
  },
]
